import "@babel/polyfill";
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)
Vue.config.productionTip = false;
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // Check that click was outside the el and its descedants
      if (!(el === event.target || el.contains(event.target))) {
        // Call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
