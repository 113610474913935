import Vue from 'vue'
import Router from 'vue-router'
import home from '../views/home.vue'

Vue.use(Router);

const router = new Router({

    mode: 'history',

    scrollBehavior: function (to, from, savedPosition) {

        return {x: 0, y: 0}
    },


    routes: [
        {
            path: '/',
            name: 'home',
            component: home,
        },
        {
            path: '/specialist',
            name: 'specialist',
            component: () => import("../views/specialist.vue"),
        },

    ]
});

export default router